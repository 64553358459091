export const suggestionList = [
  {
    name: "İlker ÖZKAN",
    title: "Golden Global Yatırım Bankası Bilgi Teknolojileri Direktörü",
    content:
      "“Kurum imajı ve müşteri güvenini doğrudan etkileyen data, veri merkezi ve yönetilen hizmetler alanında uçtan uça BT altyapı ihtiyaçlarını özenle karşılaştırdık.Tier-IV sertifikasına sahip Atlas Veri Merkezi üzerinden sunulan hizmetlerindeki donanım, sanallaştırma, işletim sistemi katmanının yanı sıra, genel merkez LAN/WAN altyapısının dizaynı, internet, MPLS gibi data hizmetleri ve bunlara bağlı yönetilen hizmetler de İşNet ile yolumuza devam etmemize önemli rol oynadı.Kaliteli ve kesintisiz olarak sunacak servis sağlayıcının; kamu otoritesi, banka personeli ve yazılım sağlayıcı firma ile tam uyum içinde çalışması bekleniyordu. İşNet bunu tam anlamıyla karşıladı.”",
    logo: "/suggestion_slider/golden-global-logo.png",
    profileImg: "/suggestion_slider/ggbank.png",
  },
  {
    name: "Gökhan SEVGEN",
    title: "OCTET Türkiye CTO’su",
    content: `<p>“Octet olarak hizla gelişen, rekabetçi fintek sektöründe fark yaratan güvenli, hızlı, ölçeklenebilir ve yenilikçi projeler geliştirmeye hız kesmeden devam ediyoruz. Genç, yenilikçi, ortak akılla karar alan, her daim yeni teknoloji ve yaklaşımları benimseyen teknoloji ekibimizle birlikte müşteri memnuniyetini ve güvenliğini merkeze alıyoruz.İşNet bluuty Finans Topluluk Bulutu ile yönetilen hizmetler, sistem, network ve güvenlik gibi uzmanlık alanlarında ihtiyacımız olan her anda hızlı destek alabiliyoruz. İşNet ile iş birliğimizin uzun yıllar devam etmesini arzu ediyoruz.”</p>`,
    logo: "/suggestion_slider/octetlogo.png",
    profileImg: "/suggestion_slider/octet.jpg",
  },
  {
    name: "Murat SEYMEN",
    title:
      "KAFA RADYO Bilişim ve Teknoloji Altyapısından Sorumlu Teknik Müdürü",
    content: `“Ofiste kullandığımız genişbant metro Ethernet internet altyapısından, telefon bağlantılarını kurduğumuz bulut santrale; canlı yayınlarda kullandığımız uydu internet erişiminden, e-belge çözümlerine kadar uçtan uça BT altyapı ihtiyaçlarımızı İşNet’ten karşılıyoruz.Tüm bu hizmetleri tek servis sağlayıcıdan karşılamanın yanı sıra günün her saatinde, İşNet’in uzman kadrosundan hızlı destek alabilmek bizim için büyük avantaj.”`,
    logo: "/suggestion_slider/kafa-radyo-logo.png",
    profileImg: "/suggestion_slider/kafaradyo.png",
  },
  {
    name: "Ersin ERTÜRK",
    title: "Pazarama Operasyondan Sorumlu Direktörü",
    content: `<p>“RPA hizmetlerinden faydalanmaya başladıktan sonra ekip arkadaşlarımızın rutin süreçler için harcadıkları zamandan kazandık ve bu saydede çalışan memnuniyetini de artırdık.İşNet ile iş birliğimizin uzun soluklu olacağına ve bu süreçte birlikte başarılı projelere imza atacağımıza yürekten inanıyoruz.”</p>`,
    logo: "/suggestion_slider/pazaramalogo.png",
    profileImg: "/suggestion_slider/pazarama.JPEG",
  },
  {
    name: "Asım DEMİR",
    title: "Kalkınma Yatırım Bankası Sistem ve Altyapı Direktörü",
    content: `<p>“İşNet ile gerçekleştirdiğimiz projelerde maliyetimiz azalırken operasyonel verimliliğimiz arttı.Projenin hayata geçirilmesi ile mevcut BT ekiplerimiz katma değerli projelere daha fazla zaman ayırma imkanına kavuştu.İşNet’in sunduğu yüksek güvenlikli bulut ve iletişim altyapısı ile değişim ve yenilik konusunda daha cesur adımlar atmakta ve yeni teknolojileri yakından takip etmekteyiz.”</p>`,
    logo: "/suggestion_slider/kalkınma-yatırımlogo.png",
    profileImg: "/suggestion_slider/kalkınma-yatırım1.png",
  },
];

export const featureList = [
  {
    name: "7/24 DESTEK",
    description:
      "Alanında uzman ve tecrübeli ekiplerimize her zaman ulaşma imkanı",
    img: "/information_img/support.svg",
    icon: "call",
    language: "tr",
  },
  {
    name: "81 İLDE POP NOKTASI",
    description:
      "Türkiye’nin tüm şehirlerinde yer alan POP(Point of Presence) noktalarımızla uçtan uca iletişim ve veri hizmetleri",
    img: "/information_img/pop.svg",
    icon: "pop",
    language: "tr",
  },
  {
    name: "E-DÖNÜŞÜMDE GÜVEN",
    description:
      "Sahip olduğumuz ileri teknoloji ve yedekli bulut altyapımızla sunduğumuz geniş kapsamlı e-dönüşüm hizmetleri",
    img: "/information_img/e-donusum.svg",
    icon: "edonusum",
    language: "tr",
  },
  {
    name: "DİJİTALLEŞME ÇÖZÜMLERİ",
    description:
      "Siber güvenlik, robotik süreç otomasyonu ve nesnelerin interneti gibi yeni nesil teknolojilerde güvenilir çözüm ortağı",
    img: "/information_img/digital.svg",
    icon: "digital",
    language: "tr",
  },
  {
    name: "24/7 SUPPORT",
    description:
      "Accessibility of our expert and experienced teams at all times",
    img: "/information_img/support.svg",
    icon: "call",
    language: "en",
  },
  {
    name: "POP POINTS IN 81 CITIES",
    description:
      "End-to-end communication and data services through POP (Point of Presence) located in all cities of Turkey",
    img: "/information_img/pop.svg",
    icon: "pop",
    language: "en",
  },
  {
    name: "SECURE E-TRANSFORMATION",
    description:
      "Comprehensive e-transformation services provided through our advanced technology and redundant cloud infrastructure",
    img: "/information_img/e-donusum.svg",
    icon: "edonusum",
    language: "en",
  },
  {
    name: "DIGITALIZATION SOLUTIONS",
    description:
      "Reliable solution partner for next-generation technologies such as cyber security, robotic process automation, and internet of things",
    img: "/information_img/digital.svg",
    icon: "digital",
    language: "en",
  },
];

export const sliderList = [
  /** STARTS WITH  THE  TR SLIDER  */
  // {
  //   id: "01",
  //   bg: "/slider_bg/pasta.png",
  //   mobileBg: "/slider_bg/mobile/pasta.png",
  //   text: "İşNet 25 Yaşında!",
  //   detail: true,
  //   url: "https://www.isnet.net.tr/bizdenhaberlericerik/isnet-25-yasinda",
  //   lang: "tr",
  //   target: true,
  // },
  {
    id: "01",
    bg: "/slider_bg/site-slider-1.png",
    mobileBg: "/slider_bg/mobile/mobil-slider-1.png",
    text: "GPU'lar Bizden, Yenilikler Sizden",
    detail: true,
    url: "https://www.isnet.net.tr/hizmetlerimiz/veri-merkezi/gpu-as-a-service",
    lang: "tr",
    target: true,
  },
  {
    id: "02",
    bg: "/slider_bg/site-slider-2.png",
    mobileBg: "/slider_bg/mobile/mobil-slider-2.png",
    text: "İşteÇağrıMerkezi Uygulaması ile Müşteri Memnuniyetini Artırın",
    detail: true,
    url: "https://www.isnet.net.tr/hizmetlerimiz/ses/istecagrimerkezi-uygulamasi",
    lang: "tr",
    target: true,
  },
  {
    id: "03",
    bg: "/slider_bg/site-slider-3.png",
    mobileBg: "/slider_bg/mobile/mobil-slider-3.png",
    text: "İşte RPA, Operasyon Süreçlerinizi Dönüştürüyor",
    detail: true,
    url: "https://www.isnet.net.tr/hizmetlerimiz/dijitallesme/robotik-surec-otomasyonu",
    lang: "tr",
    target: true,
  },
  {
    id: "04",
    bg: "/slider_bg/Dijikolay-slider-1.jpg",
    mobileBg: "/slider_bg/mobile/Dijikolay-mobil-1.png",
    text: `DijiKolay'dan e-Fatura Kampanyalarımıza Başvuranlara e-Fatura Kontörlerinde Fırsatlar!`,
    detail: true,
    url: "https://www.isbank.com.tr/is-ticari/dijikolay",
    lang: "tr",
  },
  {
    id: "05",
    bg: "/slider_bg/slider-bulut.png",
    mobileBg: "/slider_bg/mobile/slider-bulut.png",
    text: `Bilişim 500'de "Yılın Bulut Hizmeti" Kategori 1.'si İşNet!`,
    detail: true,
    url: "https://www.isnet.net.tr/bizdenhaberlericerik/bilisim-500-bulut-kategorisi-odulu",
    lang: "tr",
  },
  // {
  //   id: "04",
  //   bg: "/slider_bg/idp-1.jpg",
  //   mobileBg: "/slider_bg/mobile/idp-mobil-1.png",
  //   text: `İşNet IDP İle Belgeleriniz Otomatik İşleyin Verimliliğinizi Arttırın`,
  //   detail: true,
  //   url: "https://www.isnet.net.tr/hizmetlerimiz/dijitallesme/idp-aas",
  //   lang: "tr",
  // },
  // {
  //   id: "05",
  //   bg: "/slider_bg/slider-sanalsantral.png",
  //   mobileBg: "/slider_bg/mobile/slider-sanalsantral-mobile.png",
  //   text: `İnternete Bağlı Olduğunuz Her An, İşteSantral ile İşinizi Kesintisiz Yönetin`,
  //   detail: true,
  //   url: "/hizmetlerimiz/ses/istesantral",
  //   lang: "tr",
  // },

  {
    id: "06",
    bg: "/slider_bg/uydu-erisimi.png",
    mobileBg: "/slider_bg/mobile/uydu-erisimi.png",
    text: `Kesintisiz İletişim için İşNet Uydu İnternet!`,
    detail: true,
    url: "/hizmetlerimiz/uydu-erisimi/ka-band-canli-yayin",
    lang: "tr",
  },

  /** END OF THE  TR SLIDER  */
  {
    id: "101",
    bg: "/slider_bg/pasta.png",
    mobileBg: "/slider_bg/mobile/pasta.png",
    text: "25th Anniversary of İşNet",
    lang: "en",
  },
  {
    id: "102",
    bg: "/slider_bg/Dijikolay-slider-1.jpg",
    mobileBg: "/slider_bg/mobile/Dijikolay-mobil-1.png",
    text: `Special Offers on e-Invoice Credits for Those Who Apply to Our e-Invoice Campaigns from DijiKolay!`,
    lang: "en",
  },
  {
    id: "103",
    bg: "/slider_bg/slider-bulut.png",
    mobileBg: "/slider_bg/mobile/slider-bulut.png",
    text: ` İşNet, Ranked 1 st in the "Cloud Service of the Year" Category in Bilişim 500`,
    lang: "en",
  },
  {
    id: "104",
    bg: "/slider_bg/idp-1.jpg",
    mobileBg: "/slider_bg/mobile/idp-mobil-1.png",
    text: `Automatically Process Your Document With İşNet IDP and Increase Your Productivity`,

    lang: "en",
  },
  {
    id: "105",
    bg: "/slider_bg/slider-sanalsantral.png",
    mobileBg: "/slider_bg/mobile/slider-sanalsantral-mobile.png",
    text: `Manage Your Business Uninterruptedly with İsteSantral Whenever You Are Connected to the Internet`,

    lang: "en",
  },
  {
    id: "106",
    bg: "/slider_bg/uydu-erisimi.png",
    mobileBg: "/slider_bg/mobile/uydu-erisimi.png",
    text: `İşNet Satellite Internet for Steady Communication!`,

    lang: "en",
  },
];
